mat-drawer.mat-drawer.slide-off{
  transform: none !important;
}

.text-center .mdc-tooltip__surface {
 text-align: center !important;
}

.skeleton-loader.progress-dark {
  @apply dark:bg-gray-800;
}

.text-left .mat-sort-header-content{
  text-align: left !important;
}

img:not([src]) {
  visibility: hidden;
  border: none;
}
